import { Box, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DangerBulletIcon } from 'assets/icons/DangerBulletIcon';
import { ExternalLinkIcon } from 'assets/icons/ExternalLinkIcon/icon';
import { IsolatedIcon } from 'assets/icons/IsolatedIcon';
import { WarningBulletIcon } from 'assets/icons/WarningBulletIcon';
import classNames from 'classnames';
import { DateTime } from 'components/DateTime';
import { Spoiler } from 'components/Spoiler';
import { Table } from 'components/Table';
import { IS_HIDE_ASSET_STATUSES } from 'global/environments';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AssetIssueReason, AssetNetworkInterface, AssetStatus, AssetStatusReasons } from 'services/assets/types';
import { useSelector } from 'store/hook';
import { getLocale } from 'utils/i18n';
import isIP from 'validator/lib/isIP';
import { BulletIcon } from 'assets/icons/BulletIcon';
import { showAssetStatus } from '../../index';
import { daysToMillis, protoToTimestamp } from '../../../../utils/date';
import styles from './styles';
import { PropertiesProps } from './types';

const useStyles = makeStyles(styles);

const codesMap: {[key in AssetStatusReasons]: string} = {
  COMPONENT_TURNED_OFF: 'COMPONENT TURNED OFF',
  COMPONENT_NOT_INSTALLED: 'COMPONENT NOT INSTALLED',
  COMPONENT_MALFUNCTION: '',
  NO_TELEMETRY_MORE_THAN_X_DAYS: 'NO TELEMETRY',
  AV_BASES_OUTDATED: 'AV BASES OUTDATED',
  OPTION_TURNED_OFF: 'OPTION TURNED OFF',
  TELEMETRY_LOSS: 'TELEMETRY LOSS',
  KPSN_EXPIRATION: 'KPSN EXPIRATION',
};

function formatNoTelemetryMessage(daysWithoutTelemetry: number | undefined, t: (s: string, args?: any) => string) {
  if (daysWithoutTelemetry) {
    return [
      daysWithoutTelemetry > 3
        ? t('StatusProblem.NO TELEMETRY', { days: daysWithoutTelemetry })
        : t('StatusProblem.NO TELEMETRY HOURS', { hours: daysWithoutTelemetry * 24 }),
      t('StatusProblem.NO TELEMETRY 2'),
    ].join(' ');
  }

  return t('StatusProblem.NO TELEMETRY 2');
}

function replaceEnglishLocaleWithCurrentLocaleWithinHelpLink(
  helpLink: string,
  currentLocale: 'ru-RU' | 'en-US',
): string {
  if (currentLocale === 'en-US') {
    return helpLink;
  }
  return helpLink
    .replace('/en-US/', '/ru-RU/')
    .replace('hl=en-US', 'hl=ru-RU');
}

function getProblemKey(
  assetStatus: AssetStatus,
  level: 'warning' | 'danger' | 'neutral',
  problem: AssetIssueReason,
): string {
  if (problem.code === 'TELEMETRY_LOSS' && level === 'warning') {
    return 'StatusProblem.TELEMETRY LOSS MINOR';
  }
  if (problem.code === 'TELEMETRY_LOSS' && level === 'danger') {
    return 'StatusProblem.TELEMETRY LOSS MAJOR';
  }
  if (problem.code === 'KPSN_EXPIRATION') {
    if (typeof problem.args.expirationDate === 'string') {
      return 'StatusProblem.KPSN EXPIRATION';
    }
    return new Date().getTime() > protoToTimestamp(problem.args.expirationDate) + daysToMillis(1)
      ? 'StatusProblem.KPSN EXPIRATION FULL'
      : 'StatusProblem.KPSN EXPIRATION PARTIAL';
  }
  return `StatusProblem.${(codesMap as any)[problem.code]}`;
}

function getProblemArgs(
  assetStatus: AssetStatus,
  level: 'warning' | 'danger' | 'neutral',
  reason: AssetIssueReason,
): AssetIssueReason['args'] {
  if (level === 'danger' && reason.code === 'AV_BASES_OUTDATED') {
    return {
      ...reason.args,
      days: 30,
    };
  }
  if (level === 'warning' && reason.code === 'AV_BASES_OUTDATED') {
    return {
      ...reason.args,
      days: 7,
    };
  }
  if (reason.code === 'KPSN_EXPIRATION' && typeof reason.args.expirationDate !== 'string') {
    return {
      ...reason.args,
      expirationDate: new Date(protoToTimestamp(reason.args.expirationDate)).toLocaleDateString(getLocale(), {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }),
    };
  }
  return reason.args;
}

function formatProblem(
  t: (s: string, args?: any) => string,
  assetStatus: AssetStatus,
  level: 'warning' | 'danger' | 'neutral',
  problem: AssetIssueReason,
): string {
  if (problem.code === 'OPTION_TURNED_OFF' && problem.args.component === 'Mail Threat Protection') {
    return t('StatusProblem.MAIL THREAT PROTECTION IS TURNED OFF');
  }
  return [
    problem.args.component
      ? t(`Components.${problem.args.component}`)
      : '',
    t(getProblemKey(assetStatus, level, problem), getProblemArgs(assetStatus, level, problem)),
  ].join(' ');
}

const Problem = (
  {
    problem,
    level,
    status,
  }: { status: AssetStatus; problem: AssetIssueReason; level: 'warning' | 'danger' | 'neutral' },
) => {

  const classes = useStyles();
  const { t } = useTranslation('AssetListPage');

  return (
    <Typography className={classes.protectionIssue}>
      {level === 'danger' && <DangerBulletIcon />}
      {level === 'warning' && <WarningBulletIcon />}
      {level === 'neutral' && <BulletIcon />}

      {
        problem.helpLink
          ? (
            <a
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
              href={replaceEnglishLocaleWithCurrentLocaleWithinHelpLink(problem.helpLink, getLocale())}
            >
              {formatProblem(t, status, level, problem)}
            </a>
          )
          : formatProblem(t, status, level, problem)

      }
    </Typography>
  );
};

export const Properties = (props: PropertiesProps) => {
  const { details } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation('AssetListPage');
  const { daysForStatusOffline } = useSelector(state => state.settings);

  const daysWithoutTelemetry: number | undefined = details.protectionIssues.reasons
    .find(({ code }) => code === 'NO_TELEMETRY_MORE_THAN_X_DAYS')?.args.days;

  const isSameIssue = (firstIssue: AssetIssueReason, secondIssue: AssetIssueReason) => {
    const { component: firstComponent, product: firstProduct, level: firstLevel } = firstIssue.args;
    const { component: secondComponent, product: secondProduct, level: secondLevel } = secondIssue.args;

    return firstComponent === secondComponent && firstProduct === secondProduct && firstLevel === secondLevel;
  };

  const currentIssues = [
    ...details.protectionIssues.reasons,
    ...details.protectionIssues.additionalProblems,
  ];

  const deduplicatedIntegralReasons = details.protectionIssuesIntegral.reasons.filter(
    integralIssue => currentIssues.every(currentIssue => !isSameIssue(integralIssue, currentIssue)),
  );

  const deduplicatedIntegralAdditionalProblems = details.protectionIssuesIntegral.additionalProblems.filter(
    integralIssue => currentIssues.every(currentIssue => !isSameIssue(integralIssue, currentIssue)),
  );

  const isTelemetryInCurrentProblems = details.protectionIssues.reasons
    .some(({ code }) => code === 'TELEMETRY_LOSS');
  const currentTelemetryLevel = details.protectionIssues.reasons
    .find(({ code }) => code === 'TELEMETRY_LOSS')?.args.level;

  return (
    <Box className={classes.container}>
      <Box className={classes.property}>
        <Typography className={classes.label}>{t('Asset name')}</Typography>
        <Typography className={classes.value}>{details.hostName}</Typography>
      </Box>
      {(!IS_HIDE_ASSET_STATUSES || details.isolated) && (
        <Box className={classes.property}>
          <div className={classes.statusTitle}>
            <Typography className={classes.label}>
              {
                t('Status')
              }
            </Typography>
            <a
              className={classes.helpLink}
              href={t('StatusProblem.NO TELEMETRY LINK')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Online help')} <ExternalLinkIcon className={classes.helpLinkIcon} />
            </a>
          </div>
          {!IS_HIDE_ASSET_STATUSES && (
            <>
              <div className={
                classNames(
                  classes.statusValue,
                  {
                    [classes.statusValueWithDescription]: details.protectionIssues.reasons.length
                                                          || details.protectionIssues.additionalProblems.length
                                                          || details.protectionIssuesIntegral.reasons.length
                                                          || details.protectionIssuesIntegral.additionalProblems.length
                                                          || details.status === AssetStatus.Absent
                                                          || details.status === AssetStatus.Offline,
                  },
                )
              }
              >
                <Typography className={classes.value}>
                  <span className={classes.icon}>
                    {
                      showAssetStatus(
                        details.status,
                        daysForStatusOffline,
                        details.status === AssetStatus.Absent
                          ? details.protectionIssues
                            .reasons
                            .find(({ code }) => code === 'NO_TELEMETRY_MORE_THAN_X_DAYS')?.args.days
                          : undefined,
                      )
                    }
                  </span>
                  {t(AssetStatus[details.status], { daysForStatusOffline })}
                </Typography>
                <span className={classes.statusDate}>
                  <DateTime timestamp={details.lastSeen} type="standard" withTime />
                </span>
              </div>
              {
                details.status === AssetStatus.Absent
                  ? (
                    <div className={classes.statusDescription}>
                      {formatNoTelemetryMessage(daysWithoutTelemetry, t)}
                      <a
                        className={classes.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={t('StatusProblem.NO TELEMETRY LINK')}
                      >
                        {t('StatusProblem.NO TELEMETRY LINK TEXT')}
                      </a>.
                    </div>
                  )
                  : undefined
              }
              {
                details.status === AssetStatus.Offline
                  ? (
                    <div className={classes.statusDescription}>
                      {t('StatusDescription.Offline', { daysForStatusOffline: daysForStatusOffline.toString() })}
                      <br />
                      {t('StatusProblem.NO TELEMETRY 2')}&nbsp;
                      <a
                        className={classes.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={t('StatusProblem.NO TELEMETRY LINK')}
                      >
                        {t('StatusProblem.NO TELEMETRY LINK TEXT')}
                        <ExternalLinkIcon />
                      </a>.
                    </div>
                  )
                  : undefined
              }
              {
                details.status !== AssetStatus.Offline
                && details.status !== AssetStatus.Absent
                && (
                  details.protectionIssues.reasons.length
                  || details.protectionIssues.additionalProblems.length
                  || details.protectionIssuesIntegral.reasons.length
                  || details.protectionIssuesIntegral.additionalProblems.length
                )
                  ? (
                    <div className={classes.statusDescription}>
                      <div className={classes.currentProblems}>
                        {
                          details.status === AssetStatus.Ok
                            ? t('No current problems')
                            : t('Current problems')
                        }
                      </div>
                      {
                        details.protectionIssues.reasons.length
                          ? details.protectionIssues.reasons
                            .map(problem => (
                              <Problem
                                key={problem.code}
                                problem={problem}
                                status={details.status}
                                level={
                                  // eslint-disable-next-line no-nested-ternary
                                  details.protectionIssues.status === AssetStatus.Absent
                                    ? 'neutral'
                                    : details.protectionIssues.status === AssetStatus.Critical
                                      ? 'danger'
                                      : 'warning'
                                }
                              />
                            ))
                          : undefined
                      }
                      {
                        details.protectionIssues.additionalProblems.length
                          ? (
                            details.protectionIssues.additionalProblems.map(problem => (
                              <Problem
                                key={problem.code}
                                problem={problem}
                                status={details.status}
                                level={'warning'}
                              />
                            ))
                          )
                          : undefined
                      }
                      {
                        deduplicatedIntegralReasons.length
                        || deduplicatedIntegralAdditionalProblems.length
                          ? (
                            <Spoiler
                              label={
                                t('Problems last Nh', { hours: details.protectionIssuesIntegral.hours || 72 })
                              }
                            >
                              {
                                deduplicatedIntegralReasons.map(problem => {
                                  if (problem.code !== 'TELEMETRY_LOSS') {
                                    return (
                                      <Problem
                                        key={problem.code}
                                        problem={problem}
                                        status={details.status}
                                        level={
                                          details.protectionIssuesIntegral.status === AssetStatus.Critical
                                            ? 'danger'
                                            : 'warning'
                                        }
                                      />
                                    );
                                  }

                                  const { level } = problem.args;

                                  const showIn72h = !isTelemetryInCurrentProblems
                                    && [
                                      AssetStatus.Ok, AssetStatus.Warning, AssetStatus.Critical,
                                    ].includes(details.status);
                                  const showMajor = [AssetStatus.Warning, AssetStatus.Critical].includes(details.status)
                                    && currentTelemetryLevel === 'minor' && level === 'major';
                                  const showMinor = details.status === AssetStatus.Critical
                                    && currentTelemetryLevel === 'major' && level === 'minor';

                                  if (showIn72h || showMajor || showMinor) {
                                    return (
                                      <Problem
                                        key={problem.code}
                                        problem={problem}
                                        status={details.status}
                                        level={level === 'major' ? 'danger' : 'warning'}
                                      />
                                    );
                                  }

                                  return null;
                                })
                              }
                              {
                                deduplicatedIntegralAdditionalProblems
                                  .map(problem => (
                                    <Problem
                                      key={problem.code}
                                      problem={problem}
                                      status={details.status}
                                      level="warning"
                                    />
                                  ))
                              }
                            </Spoiler>
                          )
                          : undefined
                      }
                    </div>
                  )
                  : undefined
              }
            </>
          )}
          {details.isolated && (
            <Typography className={classes.value}>
              <IsolatedIcon className={classes.icon} />
              {t('Host is isolated')}
            </Typography>
          )}
        </Box>
      )}
      {details.networkInterfaces.length ? (
        <Box className={classes.property}>
          <Table<AssetNetworkInterface>
            columns={[
              {
                title: t('IP address'),
                field: 'ip',
                cellStyle: {
                  paddingLeft: 0,
                  height: 30,
                  padding: 0,
                },
                headerStyle: { paddingLeft: 0 },
              },
              {
                title: t('Physical address'),
                field: 'mac',
                cellStyle: {
                  height: 30,
                  padding: theme.spacing(0, 2),
                },
              },
            ]}
            data={details.networkInterfaces.map((item) => ({
              ...item,
              ip: item.ip.split('|').filter(ip => isIP(ip, 4))[0] || item.ip,
            }))}
            count={details.networkInterfaces.length}
            options={{
              search: false,
              showTitle: false,
              toolbar: false,
              sorting: false,
              paging: false,
              rowStyle: {
                backgroundColor: '#fff',
                boxShadow: 'none',
                lineHeight: theme.typography.pxToRem(24),
              },
              headerStyle: {
                fontWeight: 700,
                borderTop: 'none',
                borderBottom: 'none',
                padding: theme.spacing(1, 2),
                lineHeight: theme.typography.pxToRem(24),
                whiteSpace: 'nowrap',
              },
            }}
          />
        </Box>
      ) : null}
      {details.tenantName && (
        <Box className={classes.property}>
          <Typography className={classes.label}>{t('Tenant')}</Typography>
          <Typography className={classes.value}>{details.tenantName}</Typography>
        </Box>
      )}
      <Box className={classes.property}>
        <Typography className={classes.label}>{t('First seen ago')}</Typography>
        <Typography className={classes.value}>
          <DateTime timestamp={details.firstSeen} type="standard" withTime />
        </Typography>
      </Box>
      <Box className={classes.property}>
        <Typography className={classes.label}>{t('Last seen ago')}</Typography>
        <Typography className={classes.value}>
          <DateTime timestamp={details.lastSeen} type="standard" withTime />
        </Typography>
      </Box>
      <Box className={classes.property}>
        <Typography className={classes.label}>{t('OS')}</Typography>
        <Typography className={classes.value}>{details.osVersion}</Typography>
      </Box>
      <Box className={classes.property}>
        <Typography className={classes.label}>{t('Installed Kaspersky application')}</Typography>
        {details.productMap.map(product => (
          <Typography
            key={product.name}
            className={classes.value}
          >
            {product.name} {product.version} ({t('Last seen ago')}&nbsp;
            <DateTime timestamp={product.lastSeen} withTime />)
          </Typography>
        ))}
      </Box>
      {details.domain && (
        <Box className={classes.property}>
          <Typography className={classes.label}>{t('Domain')}</Typography>
          <Typography className={classes.value}>{details.domain}</Typography>
        </Box>
      )}
    </Box>
  );
};
